<template>
  <div>
    <MainInput
      v-model="password.value"
      label="Crea una contraseña nueva"
      placeholder="*******"
      type="password"
      large
      @click="password.focus = true"
    />

    <div v-if="validPass" id="use_v-if_here" class="password__requirements">
      <div
        class="password__requirement"
        :class="{ 'password__req-active': validPass.lower }"
      >
        a
        <div class="password__req-title">Minúscula</div>
      </div>
      <div
        class="password__requirement"
        :class="{ 'password__req-active': validPass.upper }"
      >
        A
        <div class="password__req-title">Mayúscula</div>
      </div>
      <div
        class="password__requirement"
        :class="{ 'password__req-active': validPass.character }"
      >
        8+
        <div class="password__req-title">Caracteres</div>
      </div>
    </div>
  </div>
</template>

<script>
import MainInput from '@/components/UI/MainInput.vue'

export default {
  props: ['value'],
  data: () => ({
    password: {
      focus: false,
      valid: false,
      type: 'password',
      value: '',
    },
    rules: {
      required: (value) => !!value || 'Campo requerido',
      email: (value) => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || 'Email inválido'
      },
    },
  }),
  methods: {
    togglePass() {
      const { type } = this.password
      let r = ''
      type === 'password' ? (r = 'text') : (r = 'password')
      this.password.type = r
    },
  },
  computed: {
    valuePass() {
      return this.password.value
    },
    validPass() {
      const { focus, value } = this.password
      const data = {
        character: false,
        lower: false,
        upper: false,
        isActive: false,
      }

      if (!focus) return data
      data.isActive = true

      if (!value) return data
      data.character = value.length > 7
      data.lower = /[a-z]/.test(value)
      data.upper = /[A-Z]/.test(value)
      return data
    },
  },
  watch: {
    value(val) {
      this.password.value = val
    },
    valuePass(val) {
      this.$emit('input', val)
    },
    validPass() {
      this.$emit('valid', Object.values(this.validPass).every(Boolean))
    },
  },
  components: {
    MainInput,
  },
}
</script>

<style lang="sass">
.password__requirements
  display: flex
  justify-content: space-around
  margin-bottom: 20px

.password__requirement
  font-size: 18px
  text-align: center
  color: #bbb

.password__req-active
  font-size: 18px
  text-align: center
  color: #333

.password__req-title
  font-size: 13px
</style>