





























import Vue from "vue";

export default Vue.extend({
  props: {
    secondary: Boolean,
    outline: Boolean,
    small: Boolean,
  },
  data: () => ({
    tabs: [
      {
        name: 'Asistente',
        isActive: true,
      },
      {
        name: 'Avanzado',
        isActive: false,
      }
    ]
  }),
  methods: {
    onClick(i: number) {
      this.tabs.forEach(tab => tab.isActive = false);
      this.tabs[i].isActive = true
      this.$emit('input', i == 1)
      this.$emit('change', i)
    }
  },

  computed: {
    move() {
      const i = this.tabs.findIndex(tab => tab.isActive)
      return {
        transform: `translateX(-${i * 100}%)`
      }
    }
  },

});
