<template>
  <div>
    <div style="padding: 20px">
      <Connections v-model="model" />
    </div>
    <div v-if="!model.selectDB" style="padding: 20px">
      <ConnStepper v-model="model" />
    </div>
  </div>
</template>

<script>
import ConnStepper from '@/components/ConnStepper.vue'
import Connections from '@/components/Connections.vue'
import { mapState, mapActions } from 'vuex'

export default {
  data: () => ({
    model: {
      isDialog: false,
      selectDB: true,
      database: '',
      technology: '',
    },
  }),
  methods: {
    ...mapActions(['getDatabases']),
  },
  computed: {
    ...mapState(['databases']),
  },
  mounted() {
    this.getDatabases()
  },
  components: {
    ConnStepper,
    Connections,
  },
}
</script>

<style lang="sass" scoped>
</style>
