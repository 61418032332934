<template>
  <section>
    <div style="height: 20px; width: 20px"></div>
    <b-table
      class="has-background-color-dark"
      checkable
      :checked-rows.sync="checkedRows"
      :data="isEmpty ? [] : list"
      :striped="false"
      :paginated="isPaginated"
      :per-page="perPage"
      :current-page.sync="currentPage"
      :pagination-simple="isPaginationSimple"
      :pagination-position="paginationPosition"
      :pagination-rounded="isPaginationRounded"
      :loading="isLoading"
    >
      <template v-for="(column, columnIdx) in columns">
        <b-table-column :key="column.id" v-bind="column" style="border: 0">
          <template
            v-if="column.searchable && !column.numeric"
            slot="searchable"
            slot-scope="props"
          >
            <template v-if="columnIdx == 0">
              {{ catchFilterRef(props.filters) }}
            </template>

            <b-input
              v-if="column.field != 'tipo'"
              v-model="props.filters[props.column.field]"
              placeholder="Buscar..."
              icon="magnify"
            />

            <b-dropdown
              v-model="props.filters.tipo"
              v-if="column.field == 'tipo'"
              aria-role="list"
            >
              <button
                class="button is-normal"
                slot="trigger"
                slot-scope="{ active }"
              >
                <span style="color: #666">{{ searchType }}</span>
                <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
              </button>

              <b-dropdown-item
                @click="
                  searchType = 'Tabla'
                  props.filters.tipo = '1'
                "
                aria-role="listitem"
                style="font-weight: 400"
                >Tabla</b-dropdown-item
              >
              <b-dropdown-item
                @click="
                  searchType = 'Vista'
                  props.filters.tipo = '2'
                "
                aria-role="listitem"
                style="font-weight: 400"
                >Vista</b-dropdown-item
              >
            </b-dropdown>
          </template>
          <template v-slot="props">
            <div
              v-if="column.field == 'tipo' && !isTable"
              class="datatable__datatype-holder"
            >
              <div class="datatable__datatype">
                {{ props.row.tipo }}
              </div>
            </div>

            <div v-if="column.field == 'tipo' && isTable">
              <div v-if="props.row.tipo == 1" class="datatable__datatype">
                Tabla
              </div>
              <div v-if="props.row.tipo == 2" class="datatable__datatype_color">
                Vista
              </div>
            </div>

            <div v-if="column.field !== 'tipo'">
              {{ props.row[column.field] }}
            </div>

            <div v-if="column.field == 'accion' && isTable">
              <b-button
                @click="
                  onActionClick(props.row)
                  props.filters = {}
                "
                >Ver</b-button
              >
            </div>
          </template>
        </b-table-column>
      </template>
    </b-table>
  </section>
</template>

<script>
import { ADMIN_TABLES, ADMIN_COLUMNS } from '@/js/constants'
import { mapActions } from 'vuex'

export default {
  props: {
    alias: Boolean,
    action: Boolean,
    isTable: Boolean,
    data: Array,
    value: Object,
    select: Boolean,
    mode: String,
  },
  data() {
    return {
      searchType: 'Elegir',
      checkedRows: [],
      isEmpty: false,
      isLoading: false,
      isPaginated: true,
      isPaginationSimple: false,
      isPaginationRounded: false,
      paginationPosition: 'bottom',
      currentPage: 1,
      perPage: 10,
      list: [],
      columns: ADMIN_TABLES,
      filters: { nombre: '', tipo: '' },
      tableId: 0,
    }
  },
  methods: {
    catchFilterRef(filters) {
      this.filters = filters
    },
    async onActionClick({ id, nombre }) {
      this.columns = []
      await this.sleep(100)
      this.columns = ADMIN_COLUMNS

      this.filters.nombre = ''
      this.filters.tipo = ''
      this.searchType = 'Elegir'
      this.tableId = id

      this.list = this.data
        .filter((d) => d.id == id)
        .map((d) => d.propiedades)[0]

      this.checkedRows = this.list.filter((d) => d.visible)

      this.$emit('input', {
        ...this.value,
        isTable: false,
        table: nombre,
      })
    },
    sleep(time) {
      return new Promise((resolve) =>
        setTimeout(() => {
          resolve()
        }, time)
      )
    },
    ...mapActions(['setCheckedTables']),
  },
  watch: {
    checkedRows(val) {
      this.setCheckedTables({
        isTable: this.isTable,
        rows: val,
        tableId: this.tableId,
      })
    },
    data(val) {
      this.list = this.data
      this.columns = ADMIN_TABLES
      this.checkedRows = this.list.filter((d) => d.visible)
    },
    isTable(val) {
      if (!val) return
      this.list = this.data
      this.columns = ADMIN_TABLES
      this.checkedRows = this.list.filter((d) => d.visible)
    },
  },
}
</script>


<style lang="sass" scoped>
.datatable__datatype
  display: block
  padding: 3px 10px
  border-radius: 5px
  background: whitesmoke
  display: inline-block
  text-align: center
  font-size: 14px

.datatable__datatype_color
  display: block
  padding: 3px 10px
  border-radius: 5px
  background: #8CD163
  display: inline-block
  text-align: center
  font-size: 14px
  color: white

.datatable__datatype-holder
  text-transform: capitalize
</style>