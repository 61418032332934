import { render, staticRenderFns } from "./MainBtn.vue?vue&type=template&id=1fb94492&scoped=true&"
import script from "./MainBtn.vue?vue&type=script&lang=ts&"
export * from "./MainBtn.vue?vue&type=script&lang=ts&"
import style0 from "./MainBtn.vue?vue&type=style&index=0&id=1fb94492&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1fb94492",
  null
  
)

export default component.exports