



































import Vue from 'vue'
import Icon from '@/components/UI/Icon.vue'
import MainBtn from '@/components/UI/MainBtn.vue'

export default Vue.extend({
  props: {
    placeholder: String,
    label: String,
    value: String,
    outline: Boolean,
    width: Number,
    large: Boolean,
    type: String,
  },
  data: () => ({
    text: '',
    mutatedType: 'text',
    showEye: false,
  }),
  methods: {
    toggleType(type) {
      if (type === 'text') return (this.mutatedType = 'password')
      this.mutatedType = 'text'
    },
    onClick(event: any) {
      this.$emit('click', event)
    },
  },
  mounted() {
    this.text = this.value
    this.showEye = this.type === 'password'
    if (this.type)
        this.mutatedType = this.type
  },
  watch: {
    value(val) {
      this.text = val
    },
    text(val) {
      this.$emit('input', val)
    },
  },
  components: {
    Icon,
    MainBtn,
  },
})
