import { LOGIN } from '@/store/init'
import { ROLES } from '@/js/constants'

export default {
  START_LOADING: (state) => {
    state.loading.isActive = true
  },
  STOP_LOADING: (state) => {
    state.loading.isActive = false
  },
  SET_AUTHENTICATED: (state, payload) => {
    state.auth.logged = true
    state.auth.requesting = false
    state.user = payload.user
  },
  SET_REQUESTING: (state) => {
    state.auth.requesting = true
  },
  SET_UNAUTHENTICATED: (state) => {
    state.auth.requesting = false
    state.auth.logged = false
    state.user = {}
  },
  SET_CONN_DIALOG: (state, payload) => {
    state.connDialog = { ...state.connDialog, ...payload }
  },
  ADD_CONNECTION: (state, payload) => {
    state.databases.push(payload)
  },
  DELETE_CONNECTION: (state, payload) => {
    state.databases = state.databases.filter((db) => db.id !== payload.id)
  },
  SET_DATABASES: (state, payload) => {
    state.databases = payload
  },
  SET_ONE_DATABASE: (state, payload) => {
    state.databases = state.databases.map((db) => {
      if (db.id === payload.id) return payload
      return db
    })
  },
  SET_TABLES: (state, { data, databaseId }) => {
    state.tables = data
    state.checkedTables = data
    if (databaseId)
      state.database = state.databases.find((db) => db.id === databaseId)
  },
  SET_LOGIN: (state, payload) => {
    state.login = { ...LOGIN, ...payload }
  },
  RESET_LOGIN: (state) => {
    state.login = LOGIN
  },
  SET_TOAST: (state, { type, msg }) => {
    state.toast = { type, msg, isActive: true }
  },
  SET_ALERT: (state, payload) => {
    state.alert = { ...state.alert, ...payload }
  },
  SET_GLOBAL: (state, payload) => {
    state.global = { ...state.global, ...payload }
  },
  SET_PROPERTIES: (state, { id }) => {
    state.properties = state.tables.filter(
      (table) => table.id === id
    )[0].propiedades
  },
  CLEAR_PROPERTIES: (state) => {
    state.properties = []
  },
  SET_PREVIEW_DATA: (state, payload) => {
    state.preview = payload
  },
  SET_LOADING_ELIPSE: (state, payload) => {
    state.loadingElipse = { ...state.loadingElipse, ...payload }
  },
  SET_CKAN_PREVIEW: (state, payload) => {
    state.ckanPreview = payload
  },
  SET_CHECKED_TABLES: (state, payload) => {
    if (payload.isTable) {
      state.checkedTables = state.checkedTables.map((table) => {
        const isVisible = payload.rows.find((r) => r.id === table.id)
        return {
          ...table,
          visible: !!isVisible,
        }
      })
    } else {
      const idx = state.tables.findIndex((table) => table.id == payload.tableId)
      const props = state.checkedTables[idx].propiedades.map((prop) => {
        const isVisible = payload.rows.find((r) => r.id == prop.id)
        return {
          ...prop,
          visible: !!isVisible,
        }
      })
      state.checkedTables[idx].propiedades = props
    }
  },
  SET_GLOBAL_UI: (state, payload) => {
    state.globalUI = { ...state.globalUI, ...payload }
  },
  SET_PERMISSIONS: (state, payload) => {
    const permissions = payload.map((d) => d.name)
    const hasPerm = (role) => permissions.some((perm) => perm == role)
    state.user = {
      ...state.user,
      roles: {
        isAdmin: hasPerm(ROLES.ADMIN),
        isSimpleReseacher: hasPerm(ROLES.SIMPLE_RESEACHER),
        isAdvancedReseacher: hasPerm(ROLES.ADVANCED_RESEACHER),
      },
    }
  }
  
}
