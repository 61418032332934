export default {
  databases: (state) => {
    const entries = {text:'Seleccione', value: 0}
    if (state.databases.length)
      return [entries, ...state.databases.map(x => ({text: x.nombre, value: x.id}))]
    return [entries]
  },
  tables: (state) => {
    const entries = {text:'Seleccione', value: 0}
    if (state.tables.length)
      return [entries, ...state.tables.map(x => ({text: x.nombre, value: x.id}))]
    return [entries]
  },
}