var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({ width: _vm.width ? _vm.width + 'px' : '100%' }),on:{"click":_vm.onClick}},[_c('div',{staticClass:"mainInput__label",class:{
      mainInput__label_large: _vm.large,
      mainInput__label_outline: _vm.outline,
    }},[_vm._v(_vm._s(_vm.label))]),_c('div',{staticClass:"mainInput__filled",class:{
      mainInput__outline: _vm.outline,
      mainInput__large: _vm.large,
    }},[((_vm.mutatedType)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.text),expression:"text"}],staticClass:"mainInput__input_filled",class:{
        mainInput__input_outline: _vm.outline,
        mainInput__input_large: _vm.large,
      },attrs:{"placeholder":_vm.placeholder,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.text)?_vm._i(_vm.text,null)>-1:(_vm.text)},on:{"change":function($event){var $$a=_vm.text,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.text=$$a.concat([$$v]))}else{$$i>-1&&(_vm.text=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.text=$$c}}}}):((_vm.mutatedType)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.text),expression:"text"}],staticClass:"mainInput__input_filled",class:{
        mainInput__input_outline: _vm.outline,
        mainInput__input_large: _vm.large,
      },attrs:{"placeholder":_vm.placeholder,"type":"radio"},domProps:{"checked":_vm._q(_vm.text,null)},on:{"change":function($event){_vm.text=null}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.text),expression:"text"}],staticClass:"mainInput__input_filled",class:{
        mainInput__input_outline: _vm.outline,
        mainInput__input_large: _vm.large,
      },attrs:{"placeholder":_vm.placeholder,"type":_vm.mutatedType},domProps:{"value":(_vm.text)},on:{"input":function($event){if($event.target.composing){ return; }_vm.text=$event.target.value}}}),(_vm.showEye)?_c('MainBtn',{attrs:{"icon":""},on:{"click":function($event){return _vm.toggleType(_vm.mutatedType)}}},[(_vm.type === 'password')?_c('Icon',{staticStyle:{"color":"#999"}},[_vm._v("eye")]):_vm._e(),(_vm.type === 'text')?_c('Icon',{staticStyle:{"color":"#999"}},[_vm._v("eye-off")]):_vm._e()],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }