<template>
  <div class="research__container">
    <div class="research__boxA">
      <div class="research__boxA_bubble">1</div>
    </div>

    <div class="research__boxB">
      <div class="research__line"></div>
      <div v-if="!hasAdvancePermission">
        <div style="margin-top: -30px; padding-bottom: 20px; font-size: 18px">
          Selecciona una base de datos y crea una consulta SQL
        </div>
        <DataProperties
          select
          alias
          type
          v-model="model"
          v-on:change="onDataTableChange"
          v-on:reset="resetQueryBuilder"
          :data="properties"
          :mode="PROPERTIES"
        />

        <vue-query-builder
          v-if="rules.length > 0"
          :maxDepth="3"
          :rules="rules"
          :labels="labels"
          v-model="query"
        >
          <template v-slot:default="slotProps">
            <query-builder-group v-bind="slotProps" :query.sync="query" />
          </template>
        </vue-query-builder>

        <div style="text-align: right">
          <MainBtn :btnDisabled="query.children.length == 0" @click="onPreview" secondary> Previsualizar </MainBtn>
        </div>
      </div>

      <Tabs
        v-if="hasAdvancePermission"
        v-model="isAdvanceQuery"
        v-on:change="onTabChange"
        style="margin-top: -50px"
      >
        <div slot="tab1">
          <DataProperties
            select
            alias
            type
            v-model="model"
            v-on:change="onDataTableChange"
            v-on:reset="resetQueryBuilder"
            :data="properties"
            :mode="PROPERTIES"
          />

          <vue-query-builder
            v-if="rules.length > 0"
            :maxDepth="3"
            :rules="rules"
            :labels="labels"
            v-model="query"
          >
            <template v-slot:default="slotProps">
              <query-builder-group v-bind="slotProps" :query.sync="query" />
            </template>
          </vue-query-builder>

          <div style="text-align: right">
            <MainBtn :btnDisabled="query.children.length == 0" @click="onPreview" secondary> Previsualizar </MainBtn>
          </div>
        </div>

        <div slot="tab2" class="research__tab2">
          <DropDown
            large
            v-model="modelAdvanced.database"
            v-on:change="onDatabaseChange"
            placeholder="Database"
            :items="databases"
            :width="210"
          />
          <textarea
            v-model="modelAdvanced.query"
            rows="5"
            cols="50"
            class="research_textarea"
            :placeholder="QUERY_PLACEHOLDER"
            style="width: 470px"
          >
          </textarea>

          <div v-if="alert.isActive" class="research__error">
            {{ alert.msg }}
          </div>

          <Tips />

          <div style="text-align: right">
            <MainBtn @click="onPreview" secondary> Previsualizar </MainBtn>
          </div>
        </div>
      </Tabs>
    </div>

    <div class="research__boxC">
      <div class="research__boxC_bubble">2</div>
      <div>
        <DataPreview
          :data="preview.data"
          :headers="preview.headers"
          footer
          style="margin-left: 20px"
        />

        <div class="research__csv-btn">
          <MainBtn :btnDisabled="preview.data.length==0" @click="onDownloadCSV">
            <Icon style="color: white">download</Icon>
            Descargar CSV
          </MainBtn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueQueryBuilder from 'vue-query-builder'
import QueryBuilderGroup from '@/components/TailwindGroup.vue'
import MainBtn from '@/components/UI/MainBtn.vue'
import Icon from '@/components/UI/Icon.vue'
import DataProperties from '@/components/DataProperties.vue'
import Tabs from '@/components/Tabs.vue'
import DataPreview from '@/components/DataPreview.vue'

import DropDown from '@/components/UI/DropDown.vue'
import Tips from '@/components/Tips.vue'
import { rulesBuilder } from '@/js/helpers.js'
import {
  PROPERTIES,
  RULES,
  QUERY,
  LABELS,
  QUERY_PLACEHOLDER,
} from '@/js/constants'
import { mapActions, mapState, mapGetters } from 'vuex'
export default {
  data: () => ({
    alert: {
      isActive: false,
      msg: '',
    },
    isAdvanceQuery: false,
    PROPERTIES,
    QUERY_PLACEHOLDER,
    modelAdvanced: {
      database: '',
      id: 0,
      query: QUERY_PLACEHOLDER,
    },
    model: {
      database: '',
      table: '',
    },
    aliasAndNames: [],
    rules: RULES,
    query: QUERY,
    labels: LABELS,
  }),
  methods: {
    onDownloadCSV() {
      /* eslint-disable @typescript-eslint/camelcase */
      this.getCSVFile({
        headers: this.preview.headers,
        query_id: this.preview.query_id,
        database_id: this.model.database,
      })
    },
    onPreview() {
      let payload = {}
      this.alert.isActive = false
      /* eslint-disable @typescript-eslint/camelcase */

      if (this.isAdvanceQuery) {
        if (this.checkAdvanceQuery()) return
        payload = {
          query_avanzada: true,
          sql: this.modelAdvanced.query,
          database_id: this.modelAdvanced.id,
        }
      } else {
        if (this.checkSimpleQuery()) return
        const isAlias = this.aliasAndNames.length > 0

        payload = {
          query_avanzada: false,
          database_id: this.model.database,
          tabla: this.model.table,
          propiedades: isAlias ? this.aliasAndNames : this.wildcard,
          structured_sql: this.query,
        }
        console.log({ payload })
      }

      this.getPreviewData(payload)
    },
    checkAdvanceQuery() {
      const cond1 = this.modelAdvanced.database.length > 0
      const cond2 = this.modelAdvanced.query != QUERY_PLACEHOLDER
      if (!cond1) {
        this.alert = {
          msg: 'Debes seleccionar una Base de Datos',
          isActive: true,
        }
        return true
      }

      if (!cond2) {
        this.alert = {
          msg: 'Debes crear una consulta SQL',
          isActive: true,
        }
        return true
      }

      return false
    },
    checkSimpleQuery() {
      return false
    },
    resetQueryBuilder() {
      this.rules = RULES
      this.query = QUERY
    },
    onDataTableChange(data) {
      this.query = QUERY
      this.aliasAndNames = data.aliasAndNames
      this.rules = rulesBuilder(data.properties)
    },
    onDatabaseChange(val) {
      this.modelAdvanced.id = val
    },
    onTabChange(index) {
      if (index === 1)
        return (this.modelAdvanced = {
          database: '',
          id: 0,
          query: QUERY_PLACEHOLDER,
        })

      this.clearProperties()
      this.resetQueryBuilder()
      this.model = { database: '', table: '' }
    },
    wildcard() {
      return this.properties.map((prop) => ({
        alias: prop.nombre,
        nombre: prop.nombre,
      }))
    },
    ...mapActions([
      'getDatabases',
      'getPreviewData',
      'getCSVFile',
      'clearProperties',
    ]),
  },
  computed: {
    hasAdvancePermission() {
      if (!this.user.roles) return false
      const { isAdmin, isAdvancedReseacher } = this.user.roles
      return isAdmin || isAdvancedReseacher
    },
    ...mapState(['user', 'properties', 'preview', 'loadingElipse']),
    ...mapGetters(['databases']),
  },
  mounted() {
    this.getDatabases()
  },
  destroyed() {
    this.clearProperties()
  },
  components: {
    VueQueryBuilder,
    QueryBuilderGroup,
    MainBtn,
    DataProperties,
    Tabs,
    DropDown,
    Icon,
    Tips,
    DataPreview,
  },
}
</script>

<style lang="sass" scoped>
.research__container
  padding: 15px 25px
  display: flex
  flex-wrap: wrap

.research__boxA
  width: 100%

.research__boxA_bubble
  margin: 10px 0
  width: 45px
  height: 45px
  font-size: 20px
  color: #555
  border-radius: 50%
  display: flex
  align-items: center
  justify-content: center
  border: 1px solid #ccc

.research__boxB
  padding-left: 25px
  display: flex

.research__line
  margin-right: 40px
  height: 100%
  width: 2px
  border-left: 1px solid #eee

.research__boxC
  width: 100%
  display: flex

.research__boxC_bubble
  margin: 10px 0
  width: 45px
  height: 45px
  font-size: 20px
  color: #555
  border-radius: 50%
  flex: 0 0 45px
  display: flex
  align-items: center
  justify-content: center
  border: 1px solid #ccc
  xbackground: #F0F4F7
  xcolor: #57637C
  xborder: 0

.research_textarea
  margin: 10px 0
  margin-top: 15px
  border: 1px solid #eee
  padding: 10px
  display: block

.research__tab2
  padding: 15px
  padding-top: 0

.research__csv-btn
  margin-top: 30px
  display: flex
  justify-content: flex-end

.research__error
  padding: 10px
  background: #FFEBE8
  margin-bottom: 20px
  border-radius: 3px
  border: 1.5px solid #E89A92
  color: #555
  boxx-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.01), 0 2px 2px 0 rgba(0, 0, 0, 0.05), 0 1px 5px 0 rgba(0, 0, 0, 0.1)
</style>
