<template>
  <div class="forgot__container">
    <div class="forgot__icon">
      <Icon>lock</Icon>
    </div>

    <MainInput
      v-model="email"
      label="Ingresa tu email para enviar una solicitud de cambio de contraseña"
      placeholder="ejemplo@gmail.com"
    />

    <Recaptcha @captchaOk="onSubmit" text="Recuperar contraseña" />
  </div>
</template>

<script>
import Vue from 'vue'
import { mapActions } from 'vuex'
import MainInput from '@/components/UI/MainInput.vue'
import Recaptcha from '@/components/Recaptcha.vue'
import Icon from '@/components/UI/Icon.vue'
import API from '@/js/API.js'
import { APP_BASE_URL } from '@/js/constants'

export default Vue.extend({
  props: {
    value: String,
  },
  data: () => ({
    email: '',
    msg1: '',
  }),
  methods: {
    async onSubmit(recaptchaToken) {
      this.setRequesting()
      const api = new API({
        url: `${APP_BASE_URL}/api-account/v1/account`,
        slash: false,
      })

      api.createEntity({ name: 'recovery-request' })
      const payload = {
        origin: '3',
        email: this.email,
        recaptchaToken,
      }

      const { error } = await api.endpoints.recoveryRequest.create(payload)

      if (error)
        return this.setLogin({
          error: true,
          msg: 'Algo salio mal!',
        })

      this.email = ''
      this.setLogin({
        alert: true,
        msg: 'Hemos enviado un mensaje a tu cuenta de email',
      })
    },
    ...mapActions(['setRequesting', 'setLogin', 'resetLogin']),
  },
  mounted() {
    this.resetLogin()
  },
  components: {
    MainInput,
    Recaptcha,
    Icon,
  },
})
</script>

<style lang="sass" scoped>
.forgot__container
  background: white
  padding: 35px 25px
  border-radius: 5px
  box-shadow: 0 3px 1px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12)

.forgot__icon
  margin-bottom: 0px
  text-align: center
  color: #777
</style>
