




























































































// :class="{ icon__color: color } :style="colorSt""
import Vue from "vue";

export default Vue.extend({
  props: {
    small: Boolean,
    color: String,
    stroke: Number
  },
  data: () => ({
    hola: 2,
  }),
  mounted() {
    // this.$refs.icon.style.color = '#666'
    // this.$refs.icon.style.width = '20px'
    // this.$refs.icon.style.display = 'inline-block'
  },
  computed: {
    colorSt() {
      return this.color ? {
        color: this.color
      } : {}
    },
    text() {
      return this.$slots.default[0].text
    }
  },


});
