<template>
  <div>
    <Card>
      <div style="font-size: 20px; color: #444; margin-bottom: 10px">Estadísticas de uso - CKAN</div>
      <div style="display: flex; align-items: center">
        <div>
          <div style="color: #444">Inicio</div>

          <div
            style="
              padding: 5px;
              border: 1px solid #bbb;
              display: inline-block;
              border-radius: 5px;
            "
          >
            <VueDatePicker
              v-model="start"
              :locale="locale"
              no-header
              color="#23edad"
              style="color: #666"
            ></VueDatePicker>
          </div>
        </div>

        <div style="margin-left: 25px">
          <div style="color: #444">Termino</div>

          <div
            style="
              padding: 5px;
              border: 1px solid #bbb;
              display: inline-block;
              border-radius: 5px;
            "
          >
            <VueDatePicker
              v-model="end"
              :locale="locale"
              no-header
              color="#23edad"
              style="color: #666"
            ></VueDatePicker>
          </div>
        </div>

        <div style="margin-left: 25px">
          <div style="height: 22px; width: 20px"></div>
          <MainBtn @click="onSubmit" large style="height: 43px">
            BUSCAR
            <Icon v-if="false">close</Icon>
          </MainBtn>
        </div>
      </div>
    </Card>
  </div>
</template>

<script>
import Card from '@/components/UI/Card.vue'
import MainBtn from '@/components/UI/MainBtn.vue'
import Icon from '@/components/UI/Icon.vue'
import { mapActions } from 'vuex'

import { VueDatePicker } from '@mathieustan/vue-datepicker'
import '@mathieustan/vue-datepicker/dist/vue-datepicker.min.css'

export default {
  // props: {
  //   value: Object,
  // },
  data: () => ({
    start: '2020-12-09',
    end: '2020-12-14',
    locale: {
      lang: 'es',
      weekDays: ['L', 'M', 'M', 'J', 'V', 'S', 'D'],
    },
  }),
  methods: {
    onSubmit() {
      this.getCKANStats({
        inicio: this.start,
        termino: this.end
      })
    },
     initDate(){
        const today = new Date().toISOString().slice(0, 10)
        const init = today.substring(0, 8) + '01'
        this.start = init
        this.end = today
      },
    ...mapActions(['getCKANStats', 'ckanPreview']),
  },
  mounted(){
    this.initDate()
  },
  components: {
    VueDatePicker,
    Card,
    MainBtn,
    Icon,
  },
}
</script>

<style lang="sass" scoped>
</style>
