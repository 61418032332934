import {LOGIN } from '@/store/init'

export default {
  auth: {
    logged: false,
    requesting: true
  },
  user: {
    nombre: '',
    id: 0,
    email: '',
    roles: {
      isAdmin: false,
      isSimpleReseacher: true,
      isAdvancedReseacher: false,
    }
  },
  loading: {
    isActive: false
  },
  toast: {
    msg: '',
    type: 'success',
    isActive: false
  },
  alert: {
    title: '',
    msg: '',
    isActive: false
  },
  connDialog: {
    data: {},
    type: '',
    isActive: false
  },
  databases: [],
  tables: [],
  properties: [],
  login: {...LOGIN },
  database: {},
  global: {
    isDBStatus: false
  },
  preview: {
    data: [],
    headers: [],
    query: ''
  },
  loadingElipse: {
    isActive: false
  },
  ckanPreview: {
    data: [
      {data: [], headers: []},
      {data: [], headers: []},
      {data: [], headers: []},
      {data: [], headers: []},
      {data: [], headers: []}
    ],
    headers: []
  },
  checkedTables: [],
  globalUI: {
    tableWidth: 900,
    isSideMenuCollapsed: false,
  }
}