<template>
  <section>
    <div style="height: 20px; width: 20px"></div>

    <div v-show="list.length === 0" class="datatable__empty">
      <div style="text-align: center">
        <div style="font-size: 20px">Previsualización</div>
        <div>No hay registros para mostrar</div>
      </div>
    </div>

    <b-field v-if="checkBoxes" grouped group-multiline>
      <div v-for="(column, index) in columns" :key="index" class="control">
        <b-checkbox v-model="column.visible">
          {{ column.label }}
        </b-checkbox>
      </div>
    </b-field>

    <div
      v-show="list.length > 0"
      class="my-table"
      :style="{ 'max-width': globalUI.tableWidth + 'px' }"
    >
      <b-table
        :data="list"
        style="width: 98%"
        :paginated="isPaginated"
        :bordered="true"
        :per-page="perPage"
        :current-page.sync="currentPage"
        :pagination-simple="isPaginationSimple"
        :pagination-position="paginationPosition"
        :default-sort="['id', 'asc']"
        :striped="true"
      >
        <b-table-column
          v-for="(column, index) in columns"
          sortable
          :key="index"
          :field="column.field"
          :label="column.label"
          :visible="column.visible"
          v-slot="props"
        >
          <div style="max-height: 75px; overflow: hidden">
            {{ props.row[column.field] }}
          </div>
        </b-table-column>
        <template slot="footer" v-if="footer">
          <th
            v-for="(column, index) in columns"
            :key="index"
            class="is-hidden-mobile"
          >
            {{ column.label }}
          </th>
        </template>
      </b-table>
      <div style="height: 20px; width: 20px"></div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    noPaginated: Boolean,
    footer: Boolean,
    checkBoxes: Boolean,
    data: Array,
    value: Object,
    headers: Array,
  },
  data() {
    return {
      dataEm: [
        { id: 1, value: 1, text: 'Tabla' },
        { id: 2, value: 2, text: 'Vista' },
      ],
      searchType: { text: 'Elegir' },
      checkedRows: [],
      isEmpty: false,
      isLoading: false,
      isPaginated: false,
      isPaginationSimple: false,
      isPaginationRounded: false,
      paginationPosition: 'bottom',
      currentPage: 1,
      perPage: 8,
      list: [],
      columns: [],
    }
  },
  computed: {
    widthStyle() {
      return {
        backgroundColor: 'blue',
        maxWidth: this.globalUI.tableWidth + 'px',
      }
    },
    ...mapState(['globalUI']),
  },

  watch: {
    data(val) {
      this.list = []
      this.list = val
      this.isPaginated = this.list.length > 8
    },
    headers(val) {
      this.columns = []
      setTimeout(() => {
        this.columns = val
      }, 100)
    },
  },
}
</script>

<style lang="css" scoped>
.my-table {
  max-width: 900px;
  overflow-x: auto !important;
  overflow-y: hidden;
}
</style>


<style lang="sass" scoped>
.datatable__datatype
  display: block
  padding: 3px 10px
  border-radius: 5px
  background: whitesmoke
  display: inline-block
  text-align: center
  font-size: 14px

.datatable__datatype_color
  display: block
  padding: 3px 10px
  border-radius: 5px
  background: #8CD163
  display: inline-block
  text-align: center
  font-size: 14px
  color: white

.datatable__datatype-holder
  text-transform: capitalize

.datatable__empty
  width: 600px
  height: 300px
  border: 1px solid #eee
  display: flex
  align-items: center
  justify-content: center
</style>