<template>
  <div class="recovery__container">
    <div class="recovery__icon">
      <Icon>lock</Icon>
    </div>
    <Password v-model="password" v-on:valid="validHandler" />
    <Recaptcha
      @captchaOk="onSubmit"
      :disable="!isValid"
      text="Cambiar contraseña"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import { mapActions } from 'vuex'
import Recaptcha from '@/components/Recaptcha.vue'
import Icon from '@/components/UI/Icon.vue'
import Password from '@/components/login/Password.vue'
import API from '@/js/API.js'
import { APP_BASE_URL } from '@/js/constants'

export default Vue.extend({
  props: {
    value: String,
  },
  data: () => ({
    isValid: false,
    password: '',
  }),
  methods: {
    async onSubmit(recaptchaToken) {
      const api = new API({
        url: `${APP_BASE_URL}/api-account/v1/account`,
        slash: false,
      })

      api.createEntity({ name: 'recovery' })

      const { pk, token } = this.$route.params
      const pathParams = [pk, token]

      const payload = {
        password: this.password,
        recaptchaToken,
      }

      const { error } = await api.endpoints.recovery.update(payload, {
        pathParams,
      })

      if (error)
        return this.setLogin({
          error: true,
          msg: 'Algo salio mal!',
        })

      this.password = ''
      this.setLogin({
        btn: true,
        alert: true,
        msg: 'Contraseña cambiada con éxito',
      })
    },
    validHandler(val) {
      this.isValid = val
    },
    ...mapActions(['setLogin', 'resetLogin']),
  },
  mounted() {
    this.resetLogin()
  },
  components: {
    Recaptcha,
    Icon,
    Password,
  },
})
</script>

<style lang="sass" scoped>
.recovery__container
  background: white
  padding: 35px 25px
  border-radius: 5px
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2) 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12)

.recovery__icon
  margin-bottom: 0px
  text-align: center
  color: #777
</style>
