var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"btn__hover",class:{
    btn__fav: _vm.fav,
    btn__fill: !_vm.secondary && !_vm.fav,
    btn__secondary: _vm.secondary,
    btn__outline: _vm.outline,
    btn__small: _vm.small,
    btn__large: _vm.large,
    btn__plain: _vm.plain,
    btn__light: _vm.light,
    btn__float: _vm.float,
    btn__icon: _vm.icon,
    btn__round: _vm.round,
    btn__dark: _vm.dark,
    btn_disabled: _vm.btnDisabled
  },style:({ width: _vm.width ? _vm.width + 'px' : 'none' }),attrs:{"disabled":_vm.btnDisabled},on:{"click":_vm.clicked}},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }