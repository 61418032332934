<template>
  <div class="dbconn__container">
    <div>
      <div v-if="value.selectDB" class="dbconn__box-a">
        <div class="dbconn__title">Bases de datos</div>
        <div style="display: flex">
          <MainBtn small @click="createDB">Nueva conexión</MainBtn>
          <div class="dbconn__spacer"></div>
          <MainBtn small outline @click="checkDBStatus">
            <i class="fas fa-sync-alt dbconn__update-icon"></i>
            Comprobar conexiones
          </MainBtn>
        </div>
      </div>

      <table v-if="value.selectDB" class="dbconn__table">
        <tr>
          <th class="dbconn__th">Nombre</th>
          <th class="dbconn__th">Creado</th>
          <th class="dbconn__th">Tecnología</th>
          <th v-if="global.isDBStatus" class="dbconn__th">
            <span style="padding: 0 20px">Status</span>
          </th>
          <th class="dbconn__th-center">Acciones</th>
        </tr>
        <tr v-for="(db, i) in databases" :key="i" class="dbconn__tr">
          <td class="dbconn__tr-title">{{ db.nombre }}</td>
          <td class="dbconn__td">{{ getFormatedDate(db.created) }}</td>
          <td class="dbconn__td">{{ db.tecnologia }}</td>
          <td class="dbconn__td" v-if="global.isDBStatus">
            <div class="dbconn__chip_holder">
              <div v-if="db.status === 1" class="dbconn__chip_a">UP</div>
              <div v-if="db.status === 0" class="dbconn__chip_b">DOWN</div>
              <div v-if="db.status === 0" class="dbconn__chip_c">
                <i class="fas fa-exclamation" style="font-size: 13px"></i>
              </div>
            </div>
          </td>

          <td class="dbconn__tr-actions">
            <MainBtn @click="updateDB(db)" icon
              ><Icon style="color: #444">settings</Icon>
            </MainBtn>

            <MainBtn @click="deleteDB(db)" icon>
              <i class="far fa-trash-alt"></i>
            </MainBtn>

            <MainBtn @click="openDB(db)" outline> Tablas </MainBtn>
          </td>
        </tr>
      </table>

      <div v-if="!value.selectDB" class="dbconn__box-b">
        <div>
          Estás conectado a:
          <span style="color: #4299e1">{{ value.database }}</span>
        </div>
        <div>
          {{ value.technology }}
        </div>
        <div>
          <MainBtn @click="getBackToDBList" outline>
            <Icon style="margin-right: 10px">back</Icon>
            Bases de datos
          </MainBtn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/UI/Icon.vue'
import MainBtn from '@/components/UI/MainBtn.vue'
import { getFormatedDate } from '@/js/helpers'
import Dialog from '@/js/dialog'

import { mapState, mapActions } from 'vuex'

export default {
  props: {
    value: Object,
  },
  data: () => ({
    getFormatedDate,
  }),
  methods: {
    openDB({ id, nombre, tecnologia }) {
      this.getTables({ id })
      this.$emit('input', {
        ...this.value,
        selectDB: false,
        database: nombre,
        technology: tecnologia,
      })
    },
    async deleteDB({ id, nombre }) {
      const response = await Dialog.open('setAlert', {
        title: 'Eliminar Base de Datos',
        msg: `¿Estás seguro que deseas elimnar ${nombre}?`,
      })

      if (!response.ok) return
      this.deleteConnection({ id })
    },
    updateDB(db) {
      this.setConnDialog({
        data: db,
        type: 'update',
        title: 'Actualizar conexión',
        btnText: 'ACTUALIZAR',
        isActive: true,
      })
    },
    createDB(db) {
      this.setConnDialog({
        data: db,
        type: 'create',
        title: 'Nueva conexión',
        btnText: 'ACEPTAR',
        isActive: true,
      })
    },
    checkDBStatus(db) {
      this.getDatabasesAndStatus()
    },
    getBackToDBList() {
      this.$emit('input', {
        ...this.value,
        selectDB: true,
      })
    },
    ...mapActions([
      'setConnDialog',
      'getTables',
      'deleteConnection',
      'updateConnection',
      'getDatabasesAndStatus',
    ]),
  },
  computed: {
    myDate(val) {
      return getFormatedDate(val)
    },
    ...mapState(['databases', 'global']),
  },
  components: {
    Icon,
    MainBtn,
  },
}
</script>

<style lang="sass" scoped>
.dbconn__container
  backgroundd: #F4F5F7
  border-radius: 5px
  padding: 20px
  border: 1px solid #eee
  box-shadow: 0 3px 1px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12)

.dbconn__box-a
  margin-bottom: 20px
  display: flex
  justify-content: space-between
  align-items: Center

.dbconn__title
  font-size: 18px
  margin-bottom: 20px
  font-family: 'Poppins', sans-serif
  color: #444

.dbconn__table
  width: 100%
  table-layout: fixed

.dbconn__th
  padding: 10px 0
  font-weight: 400
  text-align: left
  font-family: 'Poppins', sans-serif
  color: #555
  font-size: 13.5px
  text-trasnform: uppercase

.dbconn__th-center
  font-weight: 400
  text-align: center
  font-family: 'Poppins', sans-serif
  color: #555
  font-size: 13.5px
  text-trasnform: uppercase

.dbconn__tr
  border-top: 1px solid #eee

.dbconn__tr-title
  padding: 20px 0
  color: #4299e1

.dbconn__td
  padding: 20px 0

.dbconn__tr-actions
  padding: 20px 0
  padding-right: 10px
  width: 200px
  display: flex
  align-items: center
  justify-content: space-between

.dbconn__box-b
  display: flex
  justify-content: space-between

.dbconn__chip_holder
  width: 120px
  text-align: center
  display: flex
  justify-content: center
  align-items: center

.dbconn__chip_a
  padding: 4px 20px
  background: #EBF8FF
  color: #5D90C4
  display: inline-block
  border-radius: 10px
  font-weight: 600
  font-size: 14px

.dbconn__chip_b
  padding: 4px 20px
  background: #FFE5E4
  color: #ED5F58
  display: inline-block
  border-radius: 10px
  font-weight: 600
  font-size: 14px

.dbconn__chip_c
  background: #FFE5E4
  color: #ED5F58
  margin-left: 5px
  width: 25px
  height: 25px
  border-radius: 50%
  display: flex
  align-items: center
  justify-content: center

.dbconn__spacer
  width: 20px
  height: 20px

.dbconn__update-icon
  color: #555
  margin-right: 5px
</style>
