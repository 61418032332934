<template>
  <div v-if="connDialog.isActive" class="conndialog__container">
    <div v-click-outside="hide" class="conndialog__box">
      <div v-if="true">
        <div class="conndialog__title">
          {{ connDialog.title }}
        </div>

        <div class="conndialog__form-1">
          <div style="width: 60%">
            <MainInput
              v-model="model.nombre"
              outline
              label="Nombre de conexión"
            />
          </div>

          <div style="margin-left: 40px; width: 30%">
            <DropDown
              v-model="model.tecnologia"
              @click="preventClickOutside = true"
              label="Database"
              large
              :width="133"
              :items="[{text: 'PostgreSQL', value: 'PostgreSQL'}]"
            />
          </div>
        </div>

        <div class="conndialog__form-2">
          <div style="width: 60%">
            <MainInput v-model="model.host" outline label="Host" />
          </div>

          <div style="margin-left: 40px; width: 30%">
            <MainInput v-model="model.puerto" outline label="Puerto" />
          </div>
        </div>

        <div class="conndialog__form-3">
          <MainInput v-model="model.database" outline label="Database" />
        </div>

        <div class="conndialog__form-4">
          <div style="width: 47%">
            <MainInput
              v-model="model.username"
              outline
              label="Nombre de usuario"
            />
          </div>

          <div style="width: 47%">
            <MainInput v-model="model.password" type="password" outline label="Contraseña" />
          </div>
        </div>
      </div>

      <div class="alert__footer">
        <MainBtn @click="hide" plain small> CANCELAR </MainBtn>

        <div class="alert__spacer"></div>

        <MainBtn @click="onSubmit" secondary small>
          {{ connDialog.btnText }}
        </MainBtn>
      </div>
    </div>
  </div>
</template>

<script>
import MainInput from '@/components/UI/MainInput.vue'
import DropDown from '@/components/UI/DropDown.vue'
import { mapState, mapActions } from 'vuex'
import MainBtn from '@/components/UI/MainBtn.vue'

// @typescript-eslint/ban-ts-comment
import ClickOutside from 'vue-click-outside'

export default {
  props: {
    value: Object,
  },
  data: () => ({
    firstClick: true,
    preventClickOutside: false,
    model: {
      nombre: '',
      tecnologia: '',
      host: '',
      puerto: '',
      database: '',
      username: '',
      password: '',
    },
  }),
  methods: {
    onSubmit() {
      if (this.connDialog.type == 'create') {
        this.createConnection({
          ...this.model,
          usuario: this.user.pk,
        })
      }

      if (this.connDialog.type == 'update') {
        this.updateConnection({
          data: {
            ...this.model,
          },
          id: this.connDialog.data.id,
        })
      }
    },
    hide() {
      if (this.firstClick) return (this.firstClick = false)
      if (this.preventClickOutside) return (this.preventClickOutside = false)
      this.setConnDialog({ isActive: false })
    },
    ...mapActions(['setConnDialog', 'createConnection', 'updateConnection']),
  },
  watch: {
    isActive(val) {
      this.firstClick = val

      if (val && this.connDialog.type === 'update') {
        this.model = { ...this.connDialog.data }
      }

      if (val && this.connDialog.type === 'create') {
        this.model = {
          nombre: '',
          tecnologia: '',
          host: '',
          puerto: '',
          database: '',
          username: '',
          password: '',
        }
      }
    },
  },
  computed: {
    isActive() {
      return this.connDialog.isActive
    },
    ...mapState(['connDialog', 'user']),
  },
  mounted() {
    this.popupItem = this.$el
  },
  components: {
    MainInput,
    DropDown,
    MainBtn,
  },
  directives: {
    ClickOutside,
  },
}
</script>

<style lang="sass" scoped>
.conndialog__container
  z-index: 800
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  background: rgb(0, 0, 0, 0.4)
  display: flex
  align-items: center
  justify-content: center

.conndialog__box
  width: 500px
  height: 480px
  background: white
  border-radius: 6px
  padding: 30px
  box-shadow: 0 11px 10px -7px rgba(0,0,0,.2), 0 24px 38px 3px rgba(0,0,0,.14), 0 9px 46px 8px rgba(0,0,0,.12)

.conndialog__title
  color: rgb(26, 32, 44)
  font-weight: 600
  font-size: 22px

.conndialog__form-1
  margin-top: 20px
  display: flex
  justify-content: flex-start
  align-items: flex-start

.conndialog__form-2
  margin-top: 10px
  display: flex
  justify-content: flex-start

.conndialog__form-3
  margin-top: 10px
  width: 45%

.conndialog__form-4
  margin-top: 10px
  display: flex
  justify-content: space-between

.alert__footer
  margin-top: 30px
  width: 100%
  display: flex
  justify-content: flex-end

.alert__spacer
  width: 20px
  height: 10px
</style>
