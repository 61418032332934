


























import Vue from "vue";

export default Vue.extend({
  props: {
    fav: Boolean,
    secondary: Boolean,
    btnDisabled: Boolean,
    outline: Boolean,
    small: Boolean,
    large: Boolean,
    light: Boolean,
    float: Boolean,
    width: Number,
    icon: Boolean,
    round: Boolean,
    dark: Boolean,
    plain: Boolean,
  },
  methods: {
    clicked(event: any) {
      this.$emit('click', event);
    }
  }
});
