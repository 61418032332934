<template>
  <div>
    <div v-if="select" style="display: flex">
      <DropDown
        large
        v-model="database"
        placeholder="Database"
        :items="databases"
        :width="210"
        v-on:change="onDatabaseChange"
      />
      <DropDown
        large
        v-model="table"
        placeholder="Tabla"
        :items="tables"
        :width="210"
        style="margin-left: 20px"
        v-on:change="onTableChange"
      />
    </div>

    <div class="dataproperties__holder">
      <table v-if="list.length <= 0" class="dataproperties__table">
        <tr class="dataproperties__tr">
          <th class="dataproperties__th-100" style="width: 100%">
            <MainBtn icon>
              <i class="fas fa-minus-square dataproperties__minus"></i>
            </MainBtn>
          </th>
          <th class="dataproperties__th" style="width: 25%">Propiedad</th>
          <th class="dataproperties__th" style="text-align: center; width: 20%">
            Tipo
          </th>
          <th class="dataproperties__th" style="width: 30%">Alias</th>
        </tr>
      </table>
      <div v-if="list.length <= 0" class="dataproperties__empty-box">
        No hay registros para mostrar
      </div>

      <div style="position: relative">
        <table v-if="list.length > 0" class="dataproperties__table">
          <tr class="dataproperties__tr">
            <th class="dataproperties__th-100">
              <MainBtn v-if="allVisible" @click="toggleAll(false)" icon>
                <i class="fas fa-minus-square dataproperties__minus"></i>
              </MainBtn>

              <MainBtn v-else @click="toggleAll(true)" icon>
                <i class="far fa-square dataproperties__square"></i>
              </MainBtn>
            </th>
            <th class="dataproperties__th">{{ name }}</th>
            <th
              v-if="showDataType | showAction"
              class="dataproperties__th"
              style="text-align: center; width: 20%"
            >
              Tipo
            </th>
            <th v-if="alias" class="dataproperties__th" style="width: 30%">Alias</th>
            <th v-if="showAction" class="dataproperties__th">Acción</th>
          </tr>
          <tr v-for="(prop, i) in list" :key="i">
            <td class="dataproperties__td-100">
              <MainBtn v-if="prop.visible" @click="toggleOne(i)" icon>
                <i class="fas fa-check-square dataproperties__toggle_active"></i>
              </MainBtn>
              <MainBtn v-else @click="toggleOne(i)" icon>
                <i class="far fa-square dataproperties__toggle"></i>
              </MainBtn>
            </td>
            <td style="padding: 18px 0">
              {{ prop.nombre }}
            </td>
            <td v-if="showDataType" class="dataproperties__datatype-holder">
              <div class="dataproperties__datatype">
                {{ prop.tipo }}
              </div>
            </td>
            <td v-if="showAction" class="dataproperties__datatype-holder">
              <div v-if="prop.tipo == 1" class="dataproperties__datatype">Tabla</div>
              <div v-if="prop.tipo == 2" class="dataproperties__datatype_color">
                Vista
              </div>
            </td>
            <td v-if="alias" style="padding: 17px 0">
              <div class="dataproperties__box-alias">
                <input v-model="prop.alias" class="dataproperties__input-alias" />
              </div>
            </td>
            <td v-if="showAction">
              <button
                @click="onActionClick(prop, i)"
                class="dataproperties__view-btn"
              >
                Ver
              </button>
            </td>
          </tr>
        </table>

        <MainBtn
          @click="onFavClick"
          v-if="mode == PROPERTIES"
          fav
          class="dataproperties__fav"
        >
          <Icon>update</Icon>
        </MainBtn>
      </div>
    </div>
  </div>
</template>

<script >
import Vue from 'vue'
import MainBtn from '@/components/UI/MainBtn.vue'
import Icon from '@/components/UI/Icon.vue'

import DropDown from '@/components/UI/DropDown.vue'
import { RESEARCH_MODE, ADMIN_MODE, PROPERTIES } from '@/js/constants'
import { mapGetters, mapActions } from 'vuex'

export default Vue.extend({
  props: {
    alias: Boolean,
    action: Boolean,
    type: Boolean,
    data: Array,
    value: Object,
    select: Boolean,
    mode: String,
  },
  data() {
    return {
      PROPERTIES,
      database: '',
      table: '',
      name: 'Propiedad',
      allVisible: true,
      index: 0,
      isTable: this.mode == ADMIN_MODE,
      list: [],
      showAction: this.mode == ADMIN_MODE,
      showDataType: this.mode != ADMIN_MODE,
    }
  },
  computed: {
    valueIsTable() {
      if (!this.value) return
      return this.value.isTable
    },
    ...mapGetters(['databases', 'tables']),
  },
  watch: {
    valueIsTable(val) {
      this.isTable = val != undefined ? val : true
    },
    data(val) {
      this.list = this.setList({
        data: val,
        mode: this.mode,
        isTable: this.mode == ADMIN_MODE,
      })
      this.name = this.setName({
        mode: this.mode,
        isTable: this.mode == ADMIN_MODE,
      })
    },
    isTable(val) {
      this.list = this.setList({
        data: this.data,
        mode: this.mode,
        isTable: val,
        index: this.index,
      })
      this.showAction = this.setAction({
        mode: this.mode,
        isTable: val,
      })
      this.name = this.setName({
        mode: this.mode,
        isTable: val,
      })
      this.showDataType = this.setDataType({
        mode: this.mode,
        isTable: val,
      })
    },
  },
  mounted() {
    this.list = this.setList({
      data: this.data,
      mode: this.mode,
      isTable: this.value.isTable,
      index: this.index,
    })
    this.showAction = this.setAction({
      mode: this.mode,
      isTable: this.value.isTable,
    })
    this.name = this.setName({
      mode: this.mode,
      isTable: this.value.isTable,
    })
    this.showDataType = this.setDataType({
      mode: this.mode,
      isTable: this.value.isTable,
    })
  },
  methods: {
    onDatabaseChange(id) {
      this.table = ''
      this.clearProperties()
      if (id){
        this.getResearchTables({ id })
        this.$emit('input', { ...this.value, database: id })  
      }
      this.$emit('reset', '')
    },
    onTableChange(id) {
      if (id){
        this.setProperties({ id })
        this.$emit('input', { ...this.value, table: this.table })
        this.$emit('reset', '')
      }
      this.loader()
    },
    setList({ data, mode, isTable, index }) {
      if (mode === ADMIN_MODE) return isTable ? data : data[index].propiedades
      if (mode === PROPERTIES) {
        return data.map((d) => ({ ...d, alias: '' }))
      }
      return data
    },
    setAction({ mode, isTable }) {
      if (mode === ADMIN_MODE) return isTable
      if (mode === PROPERTIES) return false
      return this.action
    },
    setName({ mode, isTable }) {
      if (mode === ADMIN_MODE) return isTable ? 'Tabla' : 'Propiedad'
      if (mode === PROPERTIES) return 'Propiedad'
      return 'Propiedad'
    },
    setDataType({ mode, isTable }) {
      if (mode === ADMIN_MODE) return !isTable
      if (mode === PROPERTIES) return this.type
      return this.type
    },
    toggleAll(flag) {
      this.allVisible = flag

      if (this.mode === RESEARCH_MODE) {
        this.data.forEach((prop) => {
          prop.visible = flag
        })
      }

      if (this.mode === ADMIN_MODE) {
        if (this.isTable) {
          this.data.forEach((prop) => {
            prop.visible = flag
          })
        } else {
          this.data[this.index].propiedades.forEach((prop) => {
            prop.visible = flag
          })
        }
      }

      if (this.mode === PROPERTIES)
        this.list.forEach((prop) => {
          prop.visible = flag
        })
    },
    toggleOne(i) {
      if (this.mode === RESEARCH_MODE) {
        return (this.data[i].visible = !this.data[i].visible)
      }

      if (this.mode === ADMIN_MODE) {
        if (this.isTable) {
          this.data[i].visible = !this.data[i].visible
        } else {
          const flag = this.data[this.index].propiedades[i].visible
          this.data[this.index].propiedades[i].visible = !flag
        }
      }

      if (this.mode === PROPERTIES) {
        return (this.list[i].visible = !this.list[i].visible)
      }
    },
    onActionClick({ nombre }, i) {
      this.index = i
      this.isTable = false
      this.$emit('input', {
        ...this.value,
        isTable: false,
        table: nombre,
      })
    },
    onFavClick() {
      if (this.list.length <= 0) return

      const aliasAndNames = []

      const properties = this.list
        .filter((d) => d.visible)
        .map((prop) => {
          const nombre = prop.alias.length > 0 ? prop.alias : prop.nombre
          aliasAndNames.push({
            alias: nombre,
            nombre: prop.nombre,
          })
          return { ...prop, nombre }
        })

      this.loader()
      this.$emit('change', { properties, aliasAndNames })
    },
    loader() {
      this.setLoadingElipse({ isActive: true })
      setTimeout(() => this.setLoadingElipse({ isActive: false }), 300)
    },
    ...mapActions([
      'getResearchTables',
      'setProperties',
      'clearProperties',
      'setLoadingElipse',
    ]),
  },
  components: {
    MainBtn,
    DropDown,
    Icon,
  },
})
</script>

<style lang="sass" scoped>
.dataproperties__holder
  padding: 20px 0
  padding-bottom: 30px

.dataproperties__table
  color: #444
  width: 100%
  border: 1px solid #eee
  border-radius: 10px
  min-width: 600px

.dataproperties__tr
  border-bottom: 1px solid #eee

.dataproperties__th-100
  color: #333
  padding: 10px 0
  padding-left: 16px
  background: #F4F5F7
  font-weight: 400
  text-align: left
  width: 150px
  display: flex
  align-items: center
  text-transform: uppercase
  font-size: 13px
  color: #666
  font-weight: 400
  font-family: 'Poppins', sans-serif

.dataproperties__th
  color: #333
  padding: 18px 0
  background: #F4F5F7
  font-weight: 400
  text-align: left
  text-transform: uppercase
  font-size: 13px
  color: #666
  font-weight: 400
  font-family: 'Poppins', sans-serif

.dataproperties__box-alias
  width: 120px
  border-bottom: 1px solid #ccc
  color: #4299e1

.dataproperties__input-alias
  all: unset
  width: 100%
  border: 0
  padding: 2px 10px
  height: 100%
  color: rgb(63, 66, 84)

.dataproperties__view-btn
  unset: all
  font-size: 14px
  border: 1px solid #777
  color: rgb(45, 55, 72)
  font-weight: 600
  border-radius: 5px
  padding: 5px 10px
  display: flex
  align-items: center

.dataproperties__td-100
  padding: 15px 15px
  width: 120px

.dataproperties__datatype
  display: block
  padding: 3px 10px
  border-radius: 5px
  background: whitesmoke
  display: inline-block
  text-align: center
  font-size: 14px

.dataproperties__datatype_color
  display: block
  padding: 3px 10px
  border-radius: 5px
  background: #8CD163
  display: inline-block
  text-align: center
  font-size: 14px
  color: white

.dataproperties__toggle_active
  color: #374151
  font-size: 21px

.dataproperties__toggle
  color: #999
  font-size: 21px

.dataproperties__datatype-holder
  padding: 18px 0
  text-transform: capitalize
  text-align: center

.dataproperties__minus
  color: #37415199
  font-size: 20px

.dataproperties__square
  color: #999
  font-size: 20px

.dataproperties__th-eg-100
  color: #333
  padding: 10px 0
  padding-left: 10px
  background: #F4F5F7
  font-weight: 400
  text-align: left
  width: 150px
  display: flex
  align-items: center
  text-transform: uppercase
  font-size: 13px
  color: #666
  font-weight: 400
  font-family: 'Poppins', sans-serif

.dataproperties__th-eg
  color: #333
  padding: 10px 0
  background: #F4F5F7
  font-weight: 400
  text-align: left
  text-transform: uppercase
  font-size: 13px
  color: #666
  font-weight: 400
  font-family: 'Poppins', sans-serif

.dataproperties__empty-box
  width: 100%
  min-width: 600px
  height: 200px
  border: 1px solid #eee
  border-top: 0
  border-radius: 0 0 3px 3px
  display: flex
  align-items: center
  justify-content: center
  color: #444

.dataproperties__fav
  position: absolute
  bottom: 15px
  right: 10px
</style>
