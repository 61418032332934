<template>
  <div style="padding: 50px">
    <DashboardFilter />

    <div v-for="i in [0, 1, 2, 3, 4]" :key="i">
      <div
        v-if="ckanPreview.data[i].data.length > 0"
        style="margin-top: 20px; font-size: 19px; color: #333;font-weight:600;margin-bottom: -10px"
      >
      <span style="color:#896BDA;font-size:21px">#</span>
        {{ title(i) }}
      </div>

      <DataPreview
        v-show="ckanPreview.data[i].data.length > 0"
        :data="ckanPreview.data[i].data"
        :headers="ckanPreview.data[i].headers"
        :checkBoxes="i == 0"
      />
    </div>
    
    <div v-if="ckanPreview.data[0].data.length > 0" class="home__download">
      <MainBtn @click="onSubmit">Descargar</MainBtn>
    </div>
  </div>
</template>

<script>
import DashboardFilter from '@/components/DashboardFilter.vue'
import DataPreview from '@/components/DataPreview.vue'
import { mapState } from 'vuex'
import MainBtn from '@/components/UI/MainBtn.vue'
import { mapActions } from 'vuex'

export default {
  data: () => ({
    preview: {
      data: [],
      headers: [],
    },
  }),
  computed: {
    ...mapState(['ckanPreview']),
  },
  methods: {
    title(i) {
      const titles = [
        'Todos los Registros',
        'Registro de descargas agrupados por Género',
        'Registro de descargas agrupados por Tipo de Usuario',
        'Todas las visitas por página agrupadas por Género',
        'Todas las visitas por página agrupadas por Tipo de Usuario',
      ]
      return titles[i]
    },
    onSubmit() {
      this.getCSVCKAN({
        inicio: this.ckanPreview.inicio,
        termino: this.ckanPreview.termino,
      })
    },
    ...mapActions(['getCSVCKAN']),
  },
  destroyed() {
    this.ckanPreview.data = [
      { data: [], headers: [] },
      { data: [], headers: [] },
      { data: [], headers: [] },
      { data: [], headers: [] },
      { data: [], headers: [] },
    ]
  },
  components: {
    DashboardFilter,
    DataPreview,
    MainBtn,
  },
}
</script>


<style lang="sass" scoped>
.home__download
  margin-top: 20px
  display: flex
  justify-content: flex-end
</style>
