import API from '@/js/API'
import { APP_BASE_URL } from '@/js/constants'

export default {
  startLoading: ({ commit }) => {
    commit('START_LOADING')
  },
  stopLoading: ({ commit }) => {
    commit('STOP_LOADING')
  },
  setAuthenticated: ({ commit }, payload) => {
    const { refresh, access } = payload
    if (refresh && access) {
      localStorage.setItem('tkn', payload.access)
      localStorage.setItem('ref', payload.refresh)
    }
    commit('SET_AUTHENTICATED', payload)
  },
  setRequesting: ({ commit }) => {
    commit('SET_REQUESTING')
  },
  setUnauthenticated: ({ commit }) => {
    localStorage.removeItem('tkn')
    localStorage.removeItem('ref')
    commit('SET_UNAUTHENTICATED')
  },
  setConnDialog: async ({ commit }, payload) => {
    if (payload.type !== 'update') {
      return commit('SET_CONN_DIALOG', payload)
    }
    const api = new API()
    api.createEntity({ name: 'reveal-database' })
    api.endpoints.revealDatabase
      .get({ pathParams: [payload.data.id], msg: 'Base de datos a actualizar' })
      .then(({ result, error }) => {
        if (error) return
        commit('SET_CONN_DIALOG', {
          ...payload,
          data: result,
        })
      })
  },
  createConnection: ({ commit }, payload) => {
    const api = new API()
    api.createEntity({ name: 'database' })
    api.endpoints.database
      .create(payload, { msg: 'Base de datos creada' })
      .then(({ result, error }) => {
        if (error) return
        commit('SET_CONN_DIALOG', { isActive: false })
        commit('ADD_CONNECTION', result)
      })
  },
  updateConnection: ({ commit }, payload) => {
    const api = new API()
    api.createEntity({ name: 'database' })
    api.endpoints.database
      .update(payload.data, {
        pathParams: [payload.id],
        msg: 'Base de datos actualizada',
      })
      .then(({ result, error }) => {
        if (error) return
        commit('SET_CONN_DIALOG', { isActive: false })
        commit('SET_ONE_DATABASE', result)
      })
  },
  deleteConnection: ({ commit }, { id }) => {
    const api = new API()
    api.createEntity({ name: 'database' })
    api.endpoints.database
      .delete({ pathParams: [id], msg: 'Base de datos eliminada' })
      .then(({ result, error }) => {
        if (error) return
        commit('DELETE_CONNECTION', result)
      })
  },
  getDatabases: ({ commit, state }) => {
    const api = new API()
    api.createEntity({ name: 'database-list' })
    console.log({ state })
    console.log({ usr: state.user })
    api.endpoints.databaseList
      .get()
      .then(({ result, error }) => {
        if (error) return
        commit('SET_DATABASES', result)
        commit('SET_GLOBAL', { isDBStatus: false })
      })
  },
  getDatabasesAndStatus: ({ commit, state }) => {
    const api = new API()
    api.createEntity({ name: 'database-list' })
    api.endpoints.databaseList
      .get({ queryParams: { checkStatus: true } })
      .then(({ result, error }) => {
        if (error) return
        commit('SET_DATABASES', result)
        commit('SET_GLOBAL', { isDBStatus: true })
      })
  },
  getTables: ({ commit }, { id }) => {
    const api = new API()
    api.createEntity({ name: 'tabla' })
    api.endpoints.tabla.get({ pathParams: [id] }).then(({ result, error }) => {
      if (error) return
      commit('SET_TABLES', { data: result, databaseId: id })
    })
  },
  updateTables: ({ commit }, { data, id }) => {
    const api = new API()
    api.createEntity({ name: 'tabla' })
    api.endpoints.tabla
      .update(data, { pathParams: [id], msg: 'Las tablas se han actualizado' })
      .then(({ result, error }) => {
        console.log({ result, error })
      })
  },
  setLogin: ({ commit }, payload) => {
    commit('SET_LOGIN', payload)
  },
  resetLogin: ({ commit }) => {
    commit('RESET_LOGIN')
  },
  setToast: ({ commit }, payload) => {
    commit('SET_TOAST', payload)
  },
  setAlert: ({ commit }, payload) => {
    commit('SET_ALERT', payload)
  },
  setProperties: ({ commit }, payload) => {
    commit('SET_PROPERTIES', payload)
  },
  clearProperties: ({ commit }) => {
    commit('CLEAR_PROPERTIES')
  },
  getPreviewData: ({ commit }, payload) => {
    const api = new API()
    api.createEntity({ name: 'investigador' })
    api.endpoints.investigador
      .create(payload, { pathParams: ['preview'] })
      .then(({ result, error }) => {
        if (error) return
        commit('SET_PREVIEW_DATA', result)
      })
  },
  getCSVFile: ({ commit }, payload) => {
    const api = new API()
    api.createEntity({ name: 'investigador' })
    api.endpoints.investigador
      .create(payload, { pathParams: ['csv'] })
      .then(({ result, error }) => {
        if (error) return
        const blob = new Blob([result], { type: 'text/csv' })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = 'Resultado.csv'
        link.click()
      })
  },
  getResearchTables: ({ commit }, { id }) => {
    const api = new API()
    api.createEntity({ name: 'investigador' })
    api.endpoints.investigador
      .get({ pathParams: ['tabla', id] })
      .then(({ result, error }) => {
        if (error) return
        commit('SET_TABLES', { data: result, databaseId: id })
      })
  },
  setLoadingElipse: ({ commit }, payload) => {
    commit('SET_LOADING_ELIPSE', payload)
  },
  getCKANStats: ({ commit }, payload) => {
    const api = new API()
    api.createEntity({ name: 'ckan' })
    api.endpoints.ckan
      .create(payload, { pathParams: ['estadisticas'] })
      .then(({ result, error }) => {
        if (error) return
        commit('SET_CKAN_PREVIEW', result)
      })
  },
  getCSVCKAN: ({ commit }, payload) => {
    const api = new API()
    api.createEntity({ name: 'ckan' })
    api.endpoints.ckan
      .create(payload, { pathParams: ['csv'] })
      .then(({ result, error }) => {
        if (error) return
        const blob = new Blob([result], { type: 'text/csv' })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = 'Reporte.csv'
        link.click()
      })
  },
  setCheckedTables: ({ commit }, payload) => {
    commit('SET_CHECKED_TABLES', payload)
  },
  setGlobalUI: ({ commit }, payload) => {
    commit('SET_GLOBAL_UI', payload)
  },
  setPermissions: ({ commit }, payload) => {
    commit('SET_PERMISSIONS', payload)
  },
  getPermissions: ({ commit }) => {
    const api = new API({ url: `${APP_BASE_URL}/api/v1/token` })
    api.createEntity({ name: 'permissions' })
    api.endpoints.permissions.get().then(({ result, error }) => {
      if (error) return
      commit('SET_PERMISSIONS', result)
    })
  },
}
