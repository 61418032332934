import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Login from "../views/Login.vue";
import Dashboard from "../views/Dashboard.vue";
import { extractorGuard } from '@/guards/extractorGuard'

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "login",
    component: Login,
    beforeEnter: (to, from, next) => extractorGuard(to, from, next)
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard,
    beforeEnter: (to, from, next) => extractorGuard(to, from, next)
  },
  {
    path: '/recuperar-contrasena/:pk/:token',
    name: "login",
    component: Login,
    // beforeEnter: (to, from, next) => extractorGuard(to, from, next)
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
